import React from "react";
import styled from "styled-components";
import ReactImageFallback from "react-image-fallback";
import { getImage } from "../../_utils/helper";

// icons
import upArrow from "../../../assets/icons/up-arrow.svg";
import downArrow from "../../../assets/icons/down-arrow.svg";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const Image = styled.div`
  width: 55px;
  height: 55px;
  object-fit: cover;
  > img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Data = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  p {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
    color: #0e2c5e;
    padding: 3px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  > div:nth-child(1) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 18px 15px;
    box-sizing: border-box;
    flex-grow: 1;
  }
`;

const Qty = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  input {
    display: block;
    font-size: 19px;
    font-weight: 500;
    width: 50px;
    text-align: center;
  }
  figure {
    cursor: pointer;
  }
`;

export default function TableRow(props) {
  return (
    <Row>
      <Image>
        <ReactImageFallback
          src={
            props.element.images[0]
              ? getImage(props.element.images[0], 65, 65)
              : "/addNew.svg"
          }
          fallbackImage="/not-found.svg"
          alt="Product"
        />
      </Image>
      <Data qty={true}>
        <div>
          <p>{props.element.name}</p>
          <p>${parseFloat(props.element.price).toFixed(2)} MXN</p>
          <p>SKU: {props.element.sku}</p>
        </div>
        <Qty>
          <figure
            onClick={() => {
              props.changeQty({ product: props.element, substract: true });
            }}
          >
            <img src={downArrow} alt="Icon"></img>
          </figure>
          <input
            type="number"
            min="0.0"
            step="1"
            value={props.element.qty}
            onBlur={(e) => {
              props.changeQty({ product: props.element });
            }}
            onChange={(e) => {
              props.changeQty({
                product: props.element,
                inputChange: { available: true, qty: e.target.value },
              });
            }}
          ></input>
          <figure
            onClick={() => {
              props.changeQty({ product: props.element, add: true });
            }}
          >
            <img src={upArrow} alt="Icon"></img>
          </figure>
        </Qty>
      </Data>
    </Row>
  );
}
