import React from "react";
import styled from "styled-components";

// components
import TableInput from "../../SmartInputs/TableInput";

// svg
import cleanFilters from "../../../assets/icons/CloseWhite.svg";

// data
import { getOrderData } from "../../_utils/data";

// styled components
import { SearchBox } from "../../styles";

const MainComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 115px;
  flex-shrink: 0;
  padding: 0 40px;
  box-sizing: border-box;
  > div:nth-child(2) {
    display: flex;
    width: 65%;
    border: 1px solid #e9eef5;
    border-radius: 28px;
    height: 55px;
  }
`;

const Clear = styled.figure`
  width: 55px;
  height: 55px;
  cursor: pointer;
`;

const setValueState = () => {
  let value = JSON.parse(sessionStorage.getItem("movement_order_state_type"));
  if (value) {
    if (value[0].selected && value[1].selected) return "Todas";
    if (!value[0].selected && !value[1].selected) return "Ninguna";
    if (value[0].selected) return "Completadas";
    if (value[1].selected) return "Canceladas";
  }
};

export default function Main(props) {
  return (
    <MainComponent>
      <SearchBox
        placeholder={"Buscar órden"}
        onChange={(event) => {
          props.setSearchTextState(event.target.value);
        }}
      />
      <div>
        <TableInput
          title="Responsable"
          type="text"
          popupType="EMPLOYEE"
          first={true}
          width="33%"
          inputValue={
            props.valuesSelected?.responsable
              ? props.valuesSelected?.responsable.fullname
              : ""
          }
          saveValue={(value) => {
            props.setInputValueSelected((prevState) => ({
              ...prevState,
              responsable: value,
            }));
            document.getElementById("order_state").focus();
          }}
        ></TableInput>
        <TableInput
          title="Estado"
          type="text"
          popupType="ORDER_STATE"
          width="33%"
          readOnly
          defaultValue={props.valuesSelected?.order_state}
          inputValue={props.valuesSelected?.order_state}
          saveValue={() => {
            props.setInputValueSelected((prevState) => ({
              ...prevState,
              order_state: setValueState(),
            }));
          }}
        ></TableInput>
        <TableInput
          title="Fecha"
          type="date"
          last={true}
          width="34%"
          defaultValue={props.valuesSelected?.date}
          inputValue={props.valuesSelected?.date}
          saveValue={(value) => {
            const inputs = document.getElementsByTagName("input");
            inputs[3].value = "";
            props.setInputValueSelected((prevState) => ({
              ...prevState,
              date: value,
            }));
          }}
        ></TableInput>
      </div>
      <Clear
        onClick={() => {
          getOrderData().then((orders) => {
            props.setOrders(orders);
            sessionStorage.setItem(
              "movement_order_state_type",
              JSON.stringify([
                { name: "Completadas", selected: true },
                { name: "Canceladas", selected: true },
              ])
            );
            props.setInputValueSelected(props.inputValuesBasicStructure());

            // TODO: Improve this shit --> Needed to clear date input
            const inputs = document.getElementsByTagName("input");
            inputs[3].value = "";
          });
        }}
      >
        <img src={cleanFilters} alt="Limpiar filtros" />
      </Clear>
    </MainComponent>
  );
}
