import sales from "../../assets/icons/sales.svg";
import salesSelected from "../../assets/icons/sales-green.svg";
import orders from "../../assets/icons/orders.svg";
import ordersSelected from "../../assets/icons/orders-green.svg";
import pos from "../../assets/icons/pos.svg";
import posSelected from "../../assets/icons/posSelected.svg";

const NavMenuItems = [
  {
    path: process.env.REACT_APP_POSS_ROUTE,
    icon: pos,
    iconActive: posSelected,
    name: "Puntos de venta",
  },
  {
    path: process.env.REACT_APP_SALES_ROUTE,
    icon: sales,
    iconActive: salesSelected,
    name: "Venta",
  },
  {
    path: process.env.REACT_APP_ORDERS_ROUTE,
    icon: orders,
    iconActive: ordersSelected,
    name: "Órdenes",
  },
];

export default NavMenuItems;
