import { Base64 } from "js-base64";
import Fuse from "fuse.js";

export const isElementClicked = (evt, element) => {
  let flyoutElement;
  try {
    flyoutElement = document.getElementById(element);
  } catch (err) {}

  let targetElement = evt.target;

  do {
    if (targetElement === flyoutElement) {
      return true;
    }
    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);

  return false;
};

export const getImage = (articleImage, width, height) => {
  try {
    const imageLocation = articleImage.location;
    return (
      "https://d954wi4n8olec.cloudfront.net/" +
      Base64.encode(
        JSON.stringify({
          bucket: "wallavi-inventory-storage",
          key: imageLocation,
          edits: {
            resize: {
              width: width,
              height: height,
              fit: "contain",
            },
          },
        })
      )
    );
  } catch (err) {
    return "/addNew.svg";
  }
};

export const perfectSpaceForPointSales = (
  elementsClassName,
  parentContainer
) => {
  const element = document.getElementsByClassName(elementsClassName);
  if (element.length > 0) {
    const elementWidth = element[0].clientWidth;
    const parentPadding = 17;
    const freeSpace =
      document.getElementById(parentContainer).clientWidth - parentPadding * 2;
    const elements = Math.floor(freeSpace / elementWidth);
    const margin = Math.floor((freeSpace / elements - elementWidth) / 2);
    document.getElementById(parentContainer).style.padding =
      parentPadding + "px";
    for (let i = 0; i < element.length; i++) {
      element[i].style.margin = margin - 1 + "px";
    }
  }
};

export const fuseConf = (dropdownValues) => {
  // String matcher
  let fuse;
  const options = {
    includeScore: true,
    threshold: 0.3,
    distance: 500,
  };
  if (dropdownValues) {
    fuse = new Fuse(dropdownValues, options);
  }

  return fuse;
};

export const filterDropdownValues = (fuse, event) => {
  try {
    let result = fuse.search(event.target.value);
    result = result.map((element) => element.item);
    return result;
  } catch (err) {
    //Values not available yet
  }
};

export const validateInputValues = (inputsValues) => {
  let index = [];
  inputsValues.forEach((element, i) => {
    if (!element) {
      index.push(i);
    }
  });
  return index;
};

export const listenerEnter = (data) => {
  if (data.event.code === "Enter" || data.event.code === "NumpadEnter") {
    if (validateInputValues(data.array).length === 0) {
      data.actionSuccess();
    } else {
      data.actionFailure();
    }
  }
};

export const getMonthName = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return "Enero";
    case 2:
      return "Febrero";
    case 3:
      return "Marzo";
    case 4:
      return "Abril";
    case 5:
      return "Mayo";
    case 6:
      return "Junio";
    case 7:
      return "Julio";
    case 8:
      return "Agosto";
    case 9:
      return "Septiembre";
    case 10:
      return "Octubre";
    case 11:
      return "Noviembre";
    case 12:
      return "Diciembre";
    default:
      return;
  }
};

export const getDetailedTime = (time) => {
  const timeCopy = time;

  const date = {};
  date.timeUTC = time;
  date.timestamp = new Date(date.timeUTC).getTime();
  date.localTime = new Date(timeCopy.toLocaleString());
  date.time = {
    date: new Date(date.localTime).getDate(),
    month: new Date(date.localTime).getMonth() + 1,
    year: new Date(date.localTime).getFullYear(),
    hours: new Date(date.localTime).getHours(),
    minutes: new Date(date.localTime).getMinutes().toString().padStart(2, "0"),
    seconds: new Date(date.localTime).getSeconds(),
    milliseconds: new Date(date.localTime).getMilliseconds(),
  };

  date.timestampUntilDate = new Date(
    date.time.year,
    date.time.month,
    date.time.date
  ).getTime();

  // setup order hour and minute
  date.timeFormatted = `${
    date.time.hours > 12 ? date.time.hours - 12 : date.time.hours
  }:${date.time.minutes} ${date.time.hours > 11 ? "PM" : "AM"}`;

  date.shortFormatted = `${date.time.date} de ${getMonthName(
    date.time.month
  )} de ${date.time.year}`;

  date.fullFormatted = `${date.shortFormatted} ${date.timeFormatted}`;

  return date;
};

export const sub = (b, c) => {
  let b1 = b.toString().split(".");
  let b1_max = 0;
  if (b1.length === 2) {
    b1_max = b1[1].length;
  }

  let c1 = c.toString().split(".");
  let c1_max = 0;
  if (c1.length === 2) {
    c1_max = c1[1].length;
  }

  let max_len = b1_max > c1_max ? b1_max : c1_max;

  return Number((b - c).toFixed(max_len));
};
