import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  position: absolute;
  top: 45px;
  right: 0px;
  min-width: 240px;
  background: #323946;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.4);
  padding: 10px 25px;
  & li {
    padding: 15px 0;
  }
  & li:hover {
    color: #5590ff;
  }
`;

export default function Menu(props) {
  const menu = props.items.map((item) => {
    return (
      <li
        key={item.label}
        onClick={() => {
          item.action();
          props.close();
        }}
      >
        {item.label}
      </li>
    );
  });

  return (
    <MainContainer id="menu">
      <ul>{menu}</ul>
    </MainContainer>
  );
}
