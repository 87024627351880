import React from "react";
import styled from "styled-components";

// styled components
import { SearchBox } from "../../styles";
import { getArticleData } from "../../_utils/data";

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 115px;
  flex-shrink: 0;
  padding: 0 40px;
  box-sizing: border-box;
`;

export default function Header(props) {
  return (
    <MainContainer>
      <SearchBox
        id="search"
        type="text"
        autoComplete="false"
        placeholder="Buscar artículo"
        onChange={(event) => {
          if (event.target.value === "") {
            getArticleData().then((response) => {
              props.setProducts(response);
            });
          } else {
            getArticleData().then((response) => {
              let temp = response;
              temp = temp.filter(
                (e) =>
                  `${e.name}`
                    .toLocaleLowerCase()
                    .indexOf(event.target.value.toLocaleLowerCase()) > -1
              );
              props.setProducts(temp);
            });
          }
        }}
      />
    </MainContainer>
  );
}
