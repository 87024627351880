import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import SearchBox from "./SearchBox.js";
import InputOutputBox from "./InputOutputBox";
import OrderState from "./OrderState";
import { isElementClicked } from "../_utils/helper";
import MagicInput from "./MagicInput";

// data
import {
  getArticleData,
  getWarehouseData,
  getEmployeeData,
} from "../_utils/data";

const InputContainer = styled.button`
  // Restore button default values
  background: none;
  border: none;
  color: #323946;
  padding: 0;
  // Restore button default values

  height: 55px;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  & > p {
    font-weight: 500;
    font-size: 14px;
    position: absolute;
    top: 7px;
    left: 20px;
    color: #0e2c5e;
  }
  & > figure {
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 2;
    cursor: pointer;
  }
  width: ${(props) => (props.width ? props.width : "10%")};
  > input {
    padding: ${(props) => (props.textCenter ? "0 20px" : "20px 20px 0")};
    border-collapse: collapse;
    ${({ first }) =>
      first &&
      `
      border-radius: 28px 0 0 28px;
    `}
    ${({ last }) =>
      last &&
      `
      border-radius: 0 28px 28px 0;
    `}
  }
`;

export default function TableInput(props) {
  // HELPER FUNCTIONS
  const enableProduct = () => {
    switch (props.popupType) {
      case "PRODUCT_SEARCH":
        if (!productSearch) {
          setProductState(true);
          setPopupActivatedState(true);
        }
        break;
      case "WAREHOUSE_BOX":
        if (!searchBox) {
          setSearchBoxState(true);
          setPopupActivatedState(true);
        }
        break;
      case "INPUT_OUTPUT":
        if (!IOBox) {
          setIOState(true);
          setPopupActivatedState(true);
          document.addEventListener("click", clickOutsideToClose);
        }
        break;
      case "EMPLOYEE":
        if (!employee) {
          setEmployeeState(true);
          setPopupActivatedState(true);
        }
        break;
      case "ORDER_STATE":
        if (!orderStateBox) {
          setOrderStateState(true);
          setPopupActivatedState(true);
          document.addEventListener("click", clickOutsideToCloseOrderState);
        }
        break;
      default:
        break;
    }
  };

  const disableProduct = useCallback(() => {
    switch (props.popupType) {
      case "PRODUCT_SEARCH":
        setProductState(false);
        setPopupActivatedState(false);
        break;
      case "WAREHOUSE_BOX":
        setSearchBoxState(false);
        setPopupActivatedState(false);
        break;
      case "INPUT_OUTPUT":
        setIOState(false);
        setPopupActivatedState(false);
        break;
      case "EMPLOYEE":
        setEmployeeState(false);
        setPopupActivatedState(false);
        break;
      case "ORDER_STATE":
        setOrderStateState(false);
        setPopupActivatedState(false);
        break;
      default:
        break;
    }
  }, [props.popupType]);

  const clickOutsideToCloseOrderState = (evt) => {
    if (!isElementClicked(evt, "order_state")) {
      document.removeEventListener("click", clickOutsideToClose);
      disableProduct();
    }
  };

  const clickOutsideToClose = (evt) => {
    if (!isElementClicked(evt, "input_output")) {
      document.removeEventListener("click", clickOutsideToClose);
      disableProduct();
    }
  };
  // END OF HELPER FUNCTIONS

  const [productSearch, setProductState] = useState(false);
  const [searchBox, setSearchBoxState] = useState(false);
  const [IOBox, setIOState] = useState(false);
  const [orderStateBox, setOrderStateState] = useState(false);
  const [employee, setEmployeeState] = useState(false);
  const [dropdownValues, setDropdownValueState] = useState();
  const [isFirstLoad, setLoadState] = useState(true);
  const [popup, setPopupState] = useState(null);
  const [popupValues, setPopupValuesState] = useState(null);
  const [popupActivated, setPopupActivatedState] = useState(null);
  const [containerId, setContainerId] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function savePopup(values) {
    switch (props.popupType) {
      case "PRODUCT_SEARCH":
        setPopupState(
          <SearchBox
            type="PRODUCT"
            design={props.design}
            disableProduct={() => disableProduct()}
            saveValue={props.saveValue}
            data={values}
          ></SearchBox>
        );
        break;
      case "WAREHOUSE_BOX":
        setPopupState(
          <SearchBox
            type="WAREHOUSE"
            disableProduct={() => disableProduct()}
            saveValue={props.saveValue}
            data={values}
          ></SearchBox>
        );
        break;
      case "EMPLOYEE":
        setPopupState(
          <SearchBox
            type="EMPLOYEE"
            disableProduct={() => disableProduct()}
            saveValue={props.saveValue}
            data={values}
          ></SearchBox>
        );
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (isFirstLoad) {
      switch (props.popupType) {
        case "PRODUCT_SEARCH":
          getArticleData().then((response) => {
            const articles = props.getFictional
              ? response
              : response.filter((e) => e.type !== "FICTIONAL");
            setPopupActivatedState(productSearch);
            setDropdownValueState(articles);
            setPopupValuesState(articles);
            savePopup(articles);
            setContainerId("product_input");
          });
          break;
        case "WAREHOUSE_BOX":
          getWarehouseData().then((response) => {
            setPopupActivatedState(searchBox);
            setDropdownValueState(response);
            setPopupValuesState(response);
            savePopup(response);
            setContainerId("warehouse_input");
          });
          break;
        case "EMPLOYEE":
          getEmployeeData().then((response) => {
            setPopupActivatedState(employee);
            setDropdownValueState(response);
            setPopupValuesState(response);
            savePopup(response);
            setContainerId("employee_input");
          });
          break;
        case "INPUT_OUTPUT":
          setPopupActivatedState(IOBox);
          setPopupState(
            <InputOutputBox saveValue={props.saveValue}></InputOutputBox>
          );
          setContainerId("input_output");
          break;
        case "ORDER_STATE":
          setPopupActivatedState(orderStateBox);
          setPopupState(<OrderState saveValue={props.saveValue}></OrderState>);
          setContainerId("order_state");
          break;
        default:
          break;
      }

      setLoadState(false);
    }
  }, [
    isFirstLoad,
    props.popupType,
    productSearch,
    dropdownValues,
    searchBox,
    IOBox,
    props.saveValue,
    savePopup,
    employee,
    orderStateBox,
    props.getFictional,
  ]);

  return (
    <InputContainer
      id={containerId}
      className={props.className}
      width={props.width}
      last={props.last}
      first={props.first}
      textCenter={props.textCenter}
      onFocus={() => {
        enableProduct();
      }}
      onBlur={(event) => {
        if (!(IOBox || orderStateBox)) {
          disableProduct();
        }
      }}
    >
      {popupActivated &&
        (props.popupType === "INPUT_OUTPUT"
          ? true
          : props.popupType === "ORDER_STATE"
          ? true
          : !props.disabled) &&
        popup}
      <p>{props.title}</p>
      <MagicInput
        last={props.last}
        first={props.first}
        allBorders={props.allBorders}
        textCenter={props.textCenter}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        readOnly={props.readOnly}
        type={props.type}
        popupType={props.popupType}
        updatePopup={(values) => savePopup(values)}
        dropdownValues={popupValues}
        disablePopup={() => disableProduct()}
        enablePopup={() => enableProduct()}
        saveValue={props.saveValue} // Only for input type number
        min={props.min} // Only for input type number
        selectedValue={props.inputValue}
      ></MagicInput>
    </InputContainer>
  );
}
