import React, { useEffect, useState } from "react";
import styled from "styled-components";

const OverrideButtonValues = styled.button`
  border-radius: 0 !important;
  background: none !important;
  border: none !important;
  font-family: "Roboto" !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  padding: 0 !important;
  border-radius: 0 !important;
`;

const CustomInputContainer = styled(OverrideButtonValues)`
  position: relative;
  margin: 15px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  & input {
    width: 100%;
    height: 55px;
    border: 1px solid #99a7bf;
    border-radius: 5px;
    padding-left: 20px;
    box-sizing: border-box;
    outline: none;
    font-family: "Roboto";
    font-size: 15px;
    color: #323946;
    padding-right: 10px;
  }
  & textarea {
    width: 100%;
    height: 80px;
    resize: none;
    border: 1px solid #99a7bf;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    outline: none;
    font-family: "Roboto";
    font-size: 15px;
    color: #323946;
  }
  & > p {
    position: absolute;
    top: 13px;
    left: 20px;
    font-weight: 400;
    color: #99a7bf;
    background: white;
    padding: 5px;
    transition: top 0.15s ease-out;
  }
  & figure {
    position: relative;
    cursor: pointer;
    width: 50px;
    height: 55px;
    padding: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    z-index: 1;
    box-sizing: border-box;
  }
`;

const HelperText = styled.p`
  position: absolute;
  width: max-content;
  max-width: 400px;
  padding: 15px;
  background: #dfe2e6;
  color: #323946;
  box-shadow: -2px 4px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transform: translate(-90%, 0);
  top: 50px;
  right: -400px;
  font-size: 14px;
  font-weight: 500;
  display: none;
`;

const Dropdown = styled.select`
  width: 100%;
  height: 55px;
  border: 1px solid #99a7bf;
  border-radius: 5px;
  padding-left: 20px;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto";
  font-size: 15px;
  color: #323946;
  padding-right: 10px;
`;

export default function CustomInput(props) {
  const [inputValue, setInputValue] = useState(props.value);
  const [firstExecution, setExecutionState] = useState(true);

  useEffect(() => {
    if (firstExecution) {
      onFocus(props.id);
      if (inputValue) {
        onFocus(props.id);
      }

      setExecutionState(false);
    }
  }, [firstExecution, inputValue, props.id]);

  function onFocus(element) {
    document.getElementById(element).focus();
    document
      .getElementById(element)
      .parentNode.getElementsByTagName("p")[0].style.top = "-12px";
  }

  function onBlur(element) {
    const target = document.getElementById(element);
    try {
      if (!target.value) {
        target.parentNode.getElementsByTagName("p")[0].style.top = "12px";
      } else {
        target.parentNode.getElementsByTagName("p")[0].style.top = "-12px";
      }
    } catch (err) {
      console.log(err);
    }
  }

  function showHelper() {
    try {
      document.getElementById("helper_text").style.display = "block";
    } catch (err) {
      console.log(err);
    }
  }

  function hideHelper() {
    try {
      document.getElementById("helper_text").style.display = "none";
    } catch (err) {
      console.log(err);
    }
  }

  const textarea = (
    <textarea
      id={props.id}
      pattern={props.pattern}
      required={props.required}
      onChange={(event) => {
        onBlur(props.id);
        setInputValue(event.target.value);
      }}
      value={inputValue}
    ></textarea>
  );
  const input = (
    <input
      id={props.id}
      pattern={props.pattern}
      required={props.required}
      type={props.type}
      onChange={(event) => {
        onBlur(props.id);
        setInputValue(event.target.value);
      }}
      value={inputValue}
      min={props.min}
      step="any"
    ></input>
  );

  const unitDropdown = (
    <Dropdown
      id={props.id}
      onChange={(event) => {
        onBlur(props.id);
        setInputValue(event.target.value);
      }}
    >
      <option value="PIECE" selected={props.value === "PIECE" ? true : false}>
        Pieza
      </option>
      <option
        value="KILOGRAM"
        selected={props.value === "KILOGRAM" ? true : false}
      >
        Kilo
      </option>
      <option value="LITER" selected={props.value === "LITER" ? true : false}>
        Litro
      </option>
      <option value="METER" selected={props.value === "METER" ? true : false}>
        Metro
      </option>
    </Dropdown>
  );

  const moneyDropdown = (
    <Dropdown
      id={props.id}
      onChange={(event) => {
        onBlur(props.id);
        setInputValue(event.target.value);
      }}
    >
      <option value="MXN" selected={props.value === "MXN" ? true : false}>
        Peso Mexicano
      </option>
      <option value="USD" selected={props.value === "USD" ? true : false}>
        Dólar Americano
      </option>
      <option value="EUR" selected={props.value === "EUR" ? true : false}>
        Euro
      </option>
      <option value="GBP" selected={props.value === "GBP" ? true : false}>
        Libra Esterlina
      </option>
      <option value="JPY" selected={props.value === "JPY" ? true : false}>
        Yen Japonés
      </option>
      <option value="CNY" selected={props.value === "CNY" ? true : false}>
        Yuan Chino
      </option>
    </Dropdown>
  );

  const helper = (
    <figure onMouseEnter={showHelper} onMouseLeave={hideHelper}>
      <img src="../helpButton.svg" alt="Helpbox"></img>
      <HelperText id="helper_text">{props.helper}</HelperText>
    </figure>
  );

  return (
    <CustomInputContainer
      onMouseEnter={() => onFocus(props.id)}
      onMouseLeave={() => onBlur(props.id)}
      onFocus={() => onFocus(props.id)}
      onBlur={() => onBlur(props.id)}
      onClick={(event) => event.preventDefault()}
    >
      <p>{props.placeholder}</p>
      {props.type === "textarea"
        ? textarea
        : props.type === "unitDropdown"
        ? unitDropdown
        : props.type === "currencyDropdown"
        ? moneyDropdown
        : input}
      {props.helper && helper}
    </CustomInputContainer>
  );
}
