import React from "react";
import styled from "styled-components";

import emptyArticles from "../assets/icons/emptyPOS.svg";

const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & p {
    width: 230px;
    text-align: center;
  }
`;

const Icon = styled.figure`
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
`;

export default function Main(props) {
  return (
    <EmptyContent>
      <Icon>
        <img src={emptyArticles} alt="Sin puntos de venta" />
      </Icon>
      <p>No has sido asignado a ningún punto de venta</p>
    </EmptyContent>
  );
}
