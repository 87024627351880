import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

import { Loading, Completed } from "../GenericPopup";

import close from "../../assets/icons/close.svg";

const FullScreenContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  z-index: 1;
`;

const CenteredContainer = styled.div`
  position: fixed;
  max-height: 600px;
  width: 400px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  background: white;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
`;

const Title = styled.p`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px; ;
`;

const Description = styled.p`
  font-size: 14px;
  color: #323946;
  font-weight: 300;
  max-width: 350px;
  text-align: center;
  margin-bottom: 15px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 50px;
`;

const SecondaryButton = styled.p`
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  padding: 15px;
  box-sizing: border-box;
`;

const Close = styled.figure`
  width: 54px;
  height: 54px;
`;

const CancelButton = styled.div`
  position: fixed;
  top: -26px;
  right: -26px;
  cursor: pointer;
`;

export default function PopupCancelOrder(props) {
  const [status, setStatus] = useState(null);

  const onSubmit = async (inventory) => {
    setStatus(<Loading />);
    try {
      await axios({
        method: "put",
        url: process.env.REACT_APP_ORDER_ENDPOINT,
        data: { order_id: props.order_id, inventory: inventory },
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      setStatus(<Completed type="SUCCESS" close={props.close} />);
    } catch (err) {
      setStatus(<Completed type="ERROR" close={props.cancel} />);
    }
  };

  return (
    <FullScreenContainer
      id="fullscreen_container"
      onClick={(event) => {
        if (event.target !== document.getElementById("fullscreen_container")) {
          return;
        }
        props.cancel();
      }}
    >
      {status ? (
        status
      ) : (
        <CenteredContainer>
          <CancelButton>
            <Close onClick={() => props.cancel()}>
              <img src={close} alt="Cerrar" />
            </Close>
          </CancelButton>
          <Title>{props.title}</Title>
          <Description>{props.message}</Description>
          <ButtonsContainer>
            <SecondaryButton onClick={() => onSubmit(true)}>
              Inventariar
            </SecondaryButton>
            <SecondaryButton onClick={() => onSubmit(false)}>
              No inventariar
            </SecondaryButton>
          </ButtonsContainer>
        </CenteredContainer>
      )}
    </FullScreenContainer>
  );
}
