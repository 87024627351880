import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import ReactImageFallback from "react-image-fallback";
import BarcodeReader from "react-barcode-reader";

// components
import { Loading, Completed } from "../GenericPopup";
import Header from "./components/Header";
import RightRow from "./components/RightRow";
import POSnotFound from "../POSnotFound";
import { getArticleData } from "../_utils/data";
import { getImage, sub } from "../_utils/helper";

// icons
import emptyIcon from "../../assets/icons/empty.svg";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  font-family: "Roboto";
  font-weight: 400;
  color: #000;
  box-sizing: border-box;
  justify-content: center;
`;

const Row = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  height: ${(props) => (props.height ? props.height : "65px")};
  margin-bottom: 15px;
  box-sizing: border-box;
  background: ${(props) => (props.selected ? "#5590FF" : "#fbfdff")};
  border: 1px solid #e9eef5;
  border-radius: 10px;
  flex-shrink: 0;
  p {
    color: ${(props) => (props.selected ? "white" : null)};
    user-select: none;
  }
  span {
    width: fit-content;
    padding: 8px 20px;
    color: ${(props) => (props.selected ? "white" : "#2bde73")};
    font-weight: 600;
    background: ${(props) =>
      props.selected ? "rgba(255, 255, 255, .1)" : "rgba(43, 222, 115, 0.1)"};
    border-radius: 10px;
  }
  figure {
    width: 45px;
    height: 45px;
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  :hover {
    background: ${(props) =>
      props.selected ? null : "rgba(225, 229, 237, 1)"};
  }
`;

const RowField = styled.p`
  color: ${(props) => (props.color ? props.color : "#0E2C5E")};
  width: ${(props) => (props.width ? props.width : "25%")};
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px 0 15px;
  box-sizing: border-box;
  overflow-y: scroll;
`;

const ProductsList = styled.div`
  width: 60%;
  flex-grow: 1;
  overflow-y: scroll;
`;

const Products = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow-y: scroll;
`;

const ShoppingCartContainer = styled.div`
  width: 40%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 15px;
`;

const ShoppingCartProducts = styled.div`
  flex-grow: 1;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fbfdff;
  border: 1px solid #e9eef5;
  box-sizing: border-box;
  padding: 15px 20px;
  overflow-y: scroll;
  > div {
    display: flex;
    justify-content: space-between;
    > p {
      font-size: 14px;
      color: #8894ac;
      font-weight: 500;
    }
  }
`;

const FinalReview = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e9eef5;
  background: #fbfdff;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .align {
    display: flex;
  }
  p {
    font-size: 14px;
  }
`;

const Counter = styled.div`
  margin-left: 30px;
  margin-bottom: ${(props) => (props.margin ? "30px" : null)};
  .title {
    font-weight: 500;
    color: #8894ac;
    padding-bottom: 10px;
  }
  .income {
    padding: 8px 20px;
    color: #0e2c5e;
    font-size: 14px;
    font-weight: 600;
    background: rgba(225, 229, 237, 1);
    border-radius: 10px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
  p {
    cursor: pointer;
    box-sizing: border-box;
    font-size: 16;
    border-radius: 8px;
    padding: 12px 20px;
    text-align: center;
    font-weight: 500;
    color: white;
  }
`;

const BtnCancelar = styled.p`
  background: #99a7bf;
  background-color: #323946;
`;

const BtnCobrar = styled.p`
  background-color: #5590ff;
  flex-grow: 1;
  margin-right: 30px;
`;

const EmptyContent = styled.div`
  width: 230px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
    line-height: 150%;
  }
  figure {
    width: 75px;
    height: 75px;
    margin-bottom: 30px;
  }
`;

export default function Sales(props) {
  const [salesExecution, setSalesExecution] = useState(0);
  const [products, setProducts] = useState([]);
  const [shoppingCart, setShoppingCartState] = useState([]);
  const [total, setTotal] = useState(0);
  const [state, setState] = useState(null);

  const addArticleToCar = (params = {}) => {
    let dict = {};
    shoppingCart.forEach((element) => {
      dict[element.id] = element;
    });

    let articleSelected;
    if (params.barcode) {
      products.forEach((element) => {
        if (element.sku === params.barcode) {
          articleSelected = element;
        }
      });

      if (!articleSelected) return;
    } else {
      articleSelected = params;
    }

    if (!dict[articleSelected.id]) {
      dict[articleSelected.id] = JSON.parse(JSON.stringify(articleSelected));
    }

    if (!dict[articleSelected.id]["cartTimestamp"]) {
      dict[articleSelected.id]["cartTimestamp"] = new Date();
    }

    dict[articleSelected.id]["qty"] = dict[articleSelected.id]["qty"]
      ? dict[articleSelected.id]["qty"] + 1
      : 1;

    const sorted = Object.keys(dict).sort((x, y) => {
      return (
        +new Date(dict[x].cartTimestamp) - +new Date(dict[y].cartTimestamp)
      );
    });

    const newCart = [];
    sorted.forEach((e) => {
      newCart.push(dict[e]);
    });

    setShoppingCartState(newCart);
    var elem = document.getElementById("shoppingCart");

    // Scroll to the bottom
    setTimeout(() => {
      elem.scrollTop = elem.scrollHeight;
    }, 150);
  };

  const changeQty = (params = {}) => {
    let dict = {};
    shoppingCart.forEach((element) => {
      dict[element.id] = element;
    });

    if (params.inputChange) {
      dict[params.product.id]["qty"] = params.inputChange.qty;
    } else if (params.add) {
      dict[params.product.id]["qty"] = dict[params.product.id]["qty"]
        ? +dict[params.product.id]["qty"] + 1
        : 1;
    } else if (params.substract) {
      dict[params.product.id]["qty"] = dict[params.product.id]["qty"]
        ? sub(parseFloat(dict[params.product.id]["qty"]), 1)
        : -1;
    }

    if (!params.inputChange) {
      +dict[params.product.id]["qty"] <= 0 && delete dict[params.product.id];
    }

    const sorted = Object.keys(dict).sort((x, y) => {
      return (
        +new Date(dict[x].cartTimestamp) - +new Date(dict[y].cartTimestamp)
      );
    });
    const newCart = [];
    sorted.forEach((e) => {
      newCart.push(dict[e]);
    });
    setShoppingCartState(newCart);
  };

  const cancel = () => {
    setShoppingCartState([]);
  };

  const pay = async () => {
    setState(<Loading className="center" />);
    try {
      await axios({
        method: "post",
        url: process.env.REACT_APP_ORDER_ENDPOINT,
        data: {
          pos_id: JSON.parse(sessionStorage.getItem("posSelected")).pos,
          articles: shoppingCart,
          total: total,
          subtotal: total * 0.86,
        },
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      setState(
        <Completed
          className="center"
          type="SUCCESS"
          close={() => setState(null)}
        />
      );
      setShoppingCartState([]);
    } catch (err) {
      console.log(err);
      setState(
        <Completed
          className="center"
          type="ERROR"
          close={() => setState(null)}
        />
      );
    }
  };

  useEffect(() => {
    if (salesExecution === 0 && props.availablePOSs) {
      getArticleData({
        query: `?pos_id=${
          JSON.parse(sessionStorage.getItem("posSelected")).pos
        }`,
      }).then((articles) => {
        setProducts(articles);
        setSalesExecution(salesExecution + 1);
      });
    }
  }, [props, salesExecution]);

  useEffect(() => {
    setTotal(
      shoppingCart
        .reduce(
          (sumTotal, current) => sumTotal + current.price * current.qty,
          0
        )
        .toFixed(2)
    );
  }, [shoppingCart]);

  const handleScan = (data) => {
    addArticleToCar({ barcode: data });
  };

  const handleError = (err) => {
    console.error(err);
  };

  return props.availablePOSs === false ? (
    <POSnotFound />
  ) : (
    <MainContainer isEmpty={products.length === 0}>
      <BarcodeReader
        onError={handleError}
        onScan={(e) => handleScan(e)}
      ></BarcodeReader>
      <Header setProducts={setProducts}></Header>
      {state && state}
      {products.length > 0 ? (
        <ProductsContainer>
          <ProductsList>
            <Row height="37px">
              <RowField color="#8894AC" width="10.5%">
                Foto
              </RowField>
              <RowField color="#8894AC" width="42.5%">
                Nombre
              </RowField>
              <RowField color="#8894AC" width="23.75%">
                Precio
              </RowField>
              <RowField color="#8894AC" width="23.75%">
                SKU
              </RowField>
            </Row>
            <Products>
              {products.map((e) => {
                return (
                  <Row
                    key={e.id}
                    onClick={() => {
                      addArticleToCar(e);
                    }}
                  >
                    <figure>
                      <ReactImageFallback
                        src={
                          e.images[0]
                            ? getImage(e.images[0], 50, 50)
                            : "/addNew.svg"
                        }
                        fallbackImage="/not-found.svg"
                        alt="Icon"
                      />
                    </figure>
                    <RowField width="42.5%">{e.name}</RowField>
                    <RowField width="23.75%">${e.price} MXN</RowField>
                    <RowField width="23.75%">{e.sku}</RowField>
                  </Row>
                );
              })}
            </Products>
          </ProductsList>
          <ShoppingCartContainer isEmpty={shoppingCart.length === 0}>
            <ShoppingCartProducts id="shoppingCart">
              <div>
                <p>Productos</p>
                <p>Unidades</p>
              </div>
              {shoppingCart.map((e) => {
                return (
                  <RightRow key={e.id} element={e} changeQty={changeQty} />
                );
              })}
            </ShoppingCartProducts>
            <FinalReview>
              <div className="align">
                <Counter>
                  <p className="title">Total</p>
                  <p className="income">${parseFloat(total).toFixed(2)} MXN</p>
                </Counter>
                <Counter>
                  <p className="title">Subtotal</p>
                  <p className="income">
                    ${parseFloat(total / 1.16).toFixed(2)} MXN
                  </p>
                </Counter>
              </div>
            </FinalReview>
            <Actions>
              <BtnCobrar onClick={pay}>Cobrar ${total} MXN</BtnCobrar>
              <BtnCancelar onClick={cancel}>Cancelar</BtnCancelar>
            </Actions>
          </ShoppingCartContainer>
        </ProductsContainer>
      ) : (
        <EmptyContent>
          <figure>
            <img src={emptyIcon} alt="Icon" />
          </figure>
          <p>No hay productos disponibles en el punto de venta</p>
        </EmptyContent>
      )}
    </MainContainer>
  );
}
