import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  align-self: center;
  flex-grow: 1;
`;

const ImageContainer = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const UserDescription = styled.p`
  font-size: 15px;
  color: #323946;
  font-weight: 500;
  text-align: center;
  max-width: 400px;
  margin: 30px auto;
`;

export default function NotFound(props) {
  if (!JSON.parse(sessionStorage.getItem("auth"))) {
    window.location.reload();
  } else {
    return (
      <MainContainer>
        <ImageContainer>
          <img src="/not-found.svg" alt="Not Found"></img>
        </ImageContainer>
        <UserDescription>
          La página que estás buscando no existe, puedes utilizar el menú
          lateral para seguir navegando.
        </UserDescription>
      </MainContainer>
    );
  }
}
