import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

// components
import PopupCancelOrder from "./PopupCancelOrder";
import Header from "./components/Header";
import Orders from "./components/Orders";
import DetailedOrder from "./components/DetailedOrder";
import Empty from "./components/Empty";

// data
import { getOrderData } from "../_utils/data";

// helpers
import { getDetailedTime } from "../_utils/helper";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  box-sizing: border-box;
  font-family: "Roboto";
  font-weight: 400;
`;

const Table = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 15px 0 15px;
  box-sizing: border-box;
  > div:last-child {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export default function Main(props) {
  const history = useHistory();
  const ordersPermissions =
    JSON.parse(sessionStorage.getItem("permissions")).find(
      (e) => e.area === "Órdenes"
    ) !== undefined;

  const inputValuesBasicStructure = () => {
    return {
      date: "",
      warehouse: "",
      order_state: "Todas",
      responsable: "",
    };
  };
  const [groupedOrders, setGroupedOrdersState] = useState([]);

  const [valuesSelected, setInputValueSelected] = useState(
    inputValuesBasicStructure()
  );
  const [ordersExecution, setOrdersExecutionState] = useState(true);
  const [orders, setOrders] = useState([]);
  const [orderSelected, setOrderSelected] = useState(
    orders?.length ? orders[0] : []
  );
  const [popup, setPopup] = useState(false);
  const [updateOrder, setUpdateOrder] = useState(false);
  const [search, setSearchTextState] = useState("");

  const groupOrders = (orders) => {
    const temporalObject = {};
    orders.forEach((element) => {
      const orderDate = getDetailedTime(element.timestamp);
      const orderStatusDate = getDetailedTime(element.status.timestamp);

      // Enrich Orders
      // Append units sold
      element.unitsSold = element.articles.reduce(
        (a, b) => a + (b.qty || 0),
        0
      );

      // Append formatted date
      element.date = orderDate;

      // Append formatted date to status
      element.status.date = orderStatusDate;

      // Group setup
      if (!temporalObject[orderDate.timestampUntilDate]) {
        temporalObject[orderDate.timestampUntilDate] = {
          orders: {},
          totalIncome: 0,
          date: orderDate.shortFormatted,
        };
      }

      // Enrich order group
      // Append order belonging to group
      temporalObject[orderDate.timestampUntilDate].orders[
        orderStatusDate.timestamp
      ] = element;

      // Append total income
      temporalObject[orderDate.timestampUntilDate].totalIncome += element.total;
    });

    // Get latest
    let key, firstKey;
    try {
      key = Object.keys(temporalObject).sort(function (x, y) {
        return +new Date(x) - +new Date(y);
      });

      firstKey = Object.keys(temporalObject[key[key.length - 1]].orders).sort(
        function (x, y) {
          return +new Date(x) - +new Date(y);
        }
      );

      setGroupedOrdersState(temporalObject);
      setOrderSelected(
        temporalObject[key[key.length - 1]].orders[
          firstKey[firstKey.length - 1]
        ]
      );
    } catch (err) {
      setOrderSelected([]);
    }
  };

  useEffect(() => {
    if (ordersPermissions && (ordersExecution === true || updateOrder)) {
      getOrderData({ force: true })
        .then((response) => {
          setOrders(response);
          groupOrders(response);
        })
        .catch((err) => console.log(err));

      setUpdateOrder(false);
      setOrdersExecutionState(false);
      sessionStorage.setItem(
        "movement_order_state_type",
        JSON.stringify([
          { name: "Completadas", selected: true },
          { name: "Canceladas", selected: true },
        ])
      );
    }

    if (!ordersPermissions) {
      history.push({ pathname: process.env.REACT_APP_EMPLOYEES_ROUTE });
    }
  }, [groupedOrders, history, ordersExecution, ordersPermissions, updateOrder]);

  useEffect(() => {
    getOrderData().then((orders) => {
      if (search === "") {
        setOrders(orders);
      } else {
        orders = orders.filter((e) => {
          return (
            `${e.uuid}`.indexOf(search) > -1 ||
            `${e.responsable}`
              .toLocaleLowerCase()
              .indexOf(search.toLowerCase()) > -1
          );
        });
      }
      setOrders(orders);
    });
  }, [search]);

  useEffect(() => {
    getOrderData().then((orders) => {
      if (valuesSelected.warehouse) {
        orders = orders.filter(
          (e) => valuesSelected.warehouse.id === e.warehouse_id
        );
      }
      if (valuesSelected.responsable) {
        orders = orders.filter(
          (e) => valuesSelected.responsable.id === e.responsable_id
        );
      }
      if (valuesSelected.order_state === "Completadas") {
        orders = orders?.filter((e) => e.status.completed === 1);
      } else if (valuesSelected.order_state === "Canceladas") {
        orders = orders?.filter((e) => e.status.completed === 0);
      } else if (valuesSelected.order_state === "Todas") {
        orders = orders?.filter(
          (e) => e.status.completed === 1 || e.status.completed === 0
        );
      } else orders = [];
      if (valuesSelected.date) {
        orders = orders.filter((e) => {
          const date = getDetailedTime(e.timestamp);

          return (
            valuesSelected.date ===
            `${date.time.year}-${date.time.month
              .toString()
              .padStart(2, "0")}-${date.time.date.toString().padStart(2, "0")}`
          );
        });
      }

      setOrders(orders);
      groupOrders(orders);
    });
  }, [valuesSelected]);

  return (
    <MainContainer>
      {popup && (
        <PopupCancelOrder
          title="Cancelar orden"
          message={`Está a punto de cancelar la orden #${orderSelected.id}. ¿Desea volver a inventariar los artículos?`}
          cancel={() => setPopup(false)}
          close={() => {
            setPopup(false);
            window.location.reload(true);
          }}
          order_id={orderSelected.id}
        />
      )}
      <Header
        setInputValueSelected={setInputValueSelected}
        setSearchTextState={setSearchTextState}
        valuesSelected={valuesSelected}
        setOrders={setOrders}
        inputValuesBasicStructure={inputValuesBasicStructure}
      ></Header>
      {orders?.length > 0 ? (
        <Table>
          <Orders
            orders={orders}
            setOrderSelected={setOrderSelected}
            orderSelected={orderSelected}
            groupedOrders={groupedOrders}
          ></Orders>
          <DetailedOrder
            order={orderSelected}
            setPopup={setPopup}
          ></DetailedOrder>
        </Table>
      ) : (
        <Empty></Empty>
      )}
    </MainContainer>
  );
}
