import { Auth } from "aws-amplify";
import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import { listenerEnter } from "./_utils/helper";
import { getAccountData } from "./_utils/data";
import { Loading, Completed } from "./GenericPopup";
import CustomInput from "./CustomInput";

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const CenteredContainer = styled.div`
  width: 435px;
  max-width: 100%;
  padding: 35px;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

  ${(props) =>
    props.error === true &&
    css`
      animation-name: errorAlert;
      animation-duration: 0.3s;
      animation-iteration-count: 2;
    `}
`;

const SectionTitle = styled.p`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;

const SubmitButton = styled.button`
  align-self: flex-start;
  margin-top: 15px;
  height: 45px;
  padding: 0 27px;
  box-sizing: border-box;
  border-radius: 100px;
  background: #5590ff;
  color: white;
  border: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  outline: none;
  cursor: pointer;
`;

const ForgotPasswordContainer = styled.p`
  margin-top: 65px;
  color: #323946;
  font-size: 14px;
`;

const RecoverPassword = styled.span`
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  padding: 15px 10px;
  color: #323946;
`;

export function SignIn(props) {
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [completedMessage, setProcessStatusMessage] = useState(null);
  const [error, setError] = useState(false);

  const history = useHistory();

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      try {
        const session = await Auth.signIn(
          document.getElementById("login_username").value,
          document.getElementById("login_password").value
        );
        if (session.challengeName === "NEW_PASSWORD_REQUIRED") {
          props.setCognitoUserObjectState(session);
          history.push({
            pathname: "/login/complete-new-password",
          });
        } else {
          sessionStorage.setItem("auth", true);
          await getAccountData(session);
          history.push({
            pathname: process.env.REACT_APP_SALES_ROUTE,
          });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        setProcessStatus(true);
        if (error.code === "NotAuthorizedException") {
          setProcessStatusMessage("Correo o contraseña incorrectos");
        } else if (error.code === "UserNotConfirmedException") {
          setProcessStatusMessage("El correo no ha sido validado");
        } else if (error.code === "MissingPermissions") {
          try {
            await Auth.signOut({ global: true });
            sessionStorage.clear();
            setProcessStatusMessage(
              "Lo sentimos, el usuario ingresado no cuenta con los permisos para el uso de la aplicación."
            );
          } catch (error) {
            console.log("error signing out: ", error);
          }
        } else {
          console.log(error);
          setProcessStatusMessage("Error al iniciar sesión");
        }
      }
    },
    [history, props]
  );

  useEffect(() => {
    setTimeout(function () {
      setError(false);
    }, 1500);
  }, [error]);

  useEffect(() => {
    const listener = (event) => {
      listenerEnter({
        event: event,
        array: [
          document.getElementById("login_username").value,
          document.getElementById("login_password").value,
        ],
        actionSuccess: () => onSubmit(event),
        actionFailure: () => setError(true),
      });
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onSubmit]);

  return (
    <CenteredContainer error={error}>
      {loading && <Loading />}
      {completed && (
        <Completed
          message={completedMessage}
          close={() => setProcessStatus(false)}
        />
      )}
      <SectionTitle>Inicia sesión</SectionTitle>
      <form onSubmit={(event) => onSubmit(event)}>
        <CustomInput
          id="login_username"
          placeholder="Email"
          type="email"
          required={true}
        ></CustomInput>
        <CustomInput
          id="login_password"
          placeholder="Contraseña"
          type="password"
          required={true}
        ></CustomInput>
        <SubmitButton>Iniciar sesión</SubmitButton>
      </form>
      <ForgotPasswordContainer>
        ¿Olvidaste tu contraseña?{" "}
        <StyledLink to="/password-recovery">
          <RecoverPassword>Recuperar</RecoverPassword>
        </StyledLink>
      </ForgotPasswordContainer>
    </CenteredContainer>
  );
}

export function CompleteNewPassword(props) {
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      try {
        let session;
        try {
          session = await Auth.completeNewPassword(
            props.cognitoUserObject,
            document.getElementById("new_password").value
          );
          setProcessStatus(true);
        } catch (err) {
          console.log(err);
        }

        sessionStorage.setItem("auth", true);
        getAccountData(session);
        history.push({
          pathname: process.env.REACT_APP_POSS_ROUTE,
        });
      } catch (error) {
        setLoading(false);
        setProcessStatus(true);
      }
    },
    [history, props.cognitoUserObject]
  );

  useEffect(() => {
    setTimeout(function () {
      setError(false);
    }, 1500);
  }, [error]);

  useEffect(() => {
    const listener = (event) => {
      listenerEnter({
        event: event,
        array: [document.getElementById("new_password").value],
        actionSuccess: () => onSubmit(event),
        actionFailure: () => setError(true),
      });
    };
    document.addEventListener("keydown", listener);
  }, [onSubmit]);

  return (
    <CenteredContainer>
      {loading && <Loading />}
      {completed && <Completed close={() => setProcessStatus(false)} />}
      <SectionTitle>Actualiza tu contraseña</SectionTitle>
      <form onSubmit={(event) => onSubmit(event)}>
        <CustomInput
          id="new_password"
          placeholder="Nueva contraseña"
          type="password"
          required={true}
        ></CustomInput>
        <SubmitButton>Actualizar contraseña</SubmitButton>
      </form>
    </CenteredContainer>
  );
}
