import styled from "styled-components";

export const SearchBox = styled.input`
  display: flex;
  align-items: center;
  width: 270px;
  height: 55px;
  border: 1px solid #e9eef5;
  padding: 0 30px 2px 30px;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  color: #0e2c5e;
  border-radius: 28px;
  box-sizing: border-box;
  outline: none;
  background: #fbfdff;
  ::-webkit-input-placeholder {
    color: #0e2c5e;
  }
  &:hover {
    border: 1px solid #5590ff;
  }
  &:focus {
    border: 1px solid #5590ff;
  }
`;
