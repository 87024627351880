import { Auth } from "aws-amplify";
import React, { useState } from "react";
import Dashboard from "./Dashboard";
import LogoutBasicSctructure from "./LogoutBasicStructure";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { getAccountData } from "./_utils/data";

export default function App(props) {
  const sessionStorageCompanyData = JSON.parse(
    sessionStorage.getItem("company")
  );
  const sessionStorageUserData = JSON.parse(sessionStorage.getItem("user"));

  const [accountData] = useState({
    authenticated: sessionStorage.getItem("auth") || false,
    userData: sessionStorageUserData,
    companyData: sessionStorageCompanyData,
  });

  async function sessionStatus() {
    try {
      if (!accountData.authenticated) {
        const session = await Auth.currentAuthenticatedUser();
        getAccountData(session);
      }
    } catch (err) {
      console.log(err);
    }
  }

  sessionStatus();

  return (
    <Router>
      <Switch>
        <AuthDetection path="/" {...props}></AuthDetection>
      </Switch>
    </Router>
  );
}

function AuthDetection(props) {
  // TODO: Improve this mechanism to detect authentication
  const authenticated =
    sessionStorage.getItem("auth") === "true" ? true : false;

  return (
    <Route
      render={() =>
        authenticated ? (
          <Dashboard {...props}></Dashboard>
        ) : (
          <LogoutBasicSctructure {...props}></LogoutBasicSctructure>
        )
      }
    />
  );
}
