import React, { useState } from "react";
import styled from "styled-components";

import { fuseConf, filterDropdownValues } from "../_utils/helper";

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background: #fbfdff;
  border: ${(props) => (props.allBorders ? "1px solid #E9EEF5" : "0px")};
  border-left: ${(props) => (props.first ? "0 solid" : "1px solid #E9EEF5")};
  outline: none;
  box-sizing: border-box;
  flex-shrink: 1;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  color: #0e2c5e;
  :disabled {
    color: #0e2c5e;
    opacity: 1;
  }
  background: ${(props) => (props.disableInput ? "#EBEBEB" : null)};
  ::placeholder {
    color: #0e2c5e;
    opacity: 1; /* Firefox */
  }
`;

export default function MagicInput(props) {
  const [values, saveValues] = useState([]);

  let fuseValues;
  if (props.dropdownValues) {
    fuseValues = props.dropdownValues.map(
      (element) => element.name || element.fullname
    );
    fuseValues = [
      ...fuseValues,
      ...props.dropdownValues.map((element) =>
        element.sku
          ? element.sku
          : element.address
          ? element.address
          : element.job_title
      ),
    ];
  }

  const fuse = fuseConf(fuseValues);

  return (
    <Input
      id="input"
      onKeyPress={(event) => {
        if (event.key === "Enter" || event.keyCode === 13) {
          props.saveValue(
            values[0],
            values[0]
              ? values[0].name
                ? values[0].name
                : values[0].fullname
              : ""
          );
          props.disablePopup();
          event.target.value = "";
        }
      }}
      onChange={(event) => {
        if (event.target.value === "") {
          props.updatePopup(props.dropdownValues);
        } else {
          if (
            props.popupType &&
            props.popupType !== "INPUT_OUTPUT" &&
            props.popupType !== "ORDER_STATE"
          ) {
            props.enablePopup();
            let matches = filterDropdownValues(fuse, event);
            const temporalValues = [];
            props.dropdownValues.forEach((originalValue) => {
              for (let i = 0; i < matches.length; i++) {
                if (
                  matches[i] === originalValue.name ||
                  matches[i] === originalValue.fullname ||
                  matches[i] === originalValue.sku ||
                  matches[i] === originalValue.address ||
                  matches[i] === originalValue.job_title
                ) {
                  temporalValues.push(originalValue);
                  break;
                }
              }
            });
            saveValues(temporalValues);
            props.updatePopup(temporalValues);
          }
        }

        //Only for quantity input
        if (props.type === "number") {
          props.saveValue(event.target.value, event.target.value);
        }
        if (props.type === "date") {
          props.saveValue(event.target.value, event.target.value);
        }
      }}
      onFocus={(event) => {
        event.target.placeholder = "";
        event.target.value = "";

        if (props.dropdownValues) {
          const temporalValues = [];
          props.dropdownValues.forEach((originalValue) => {
            temporalValues.push(originalValue);
          });
          saveValues(temporalValues);
          props.updatePopup(temporalValues);
        }
      }}
      onBlur={(event) => {
        if (props.type !== "date") {
          event.target.value = "";
        }
        event.target.placeholder = props.selectedValue
          ? props.selectedValue
          : props.defaultValue
          ? props.defaultValue
          : "";
      }}
      readOnly={props.readOnly}
      disabled={props.disabled}
      disableInput={
        (props.disabled ? props.disabled : false) &&
        !(props.popupType ? props.popupType === "INPUT_OUTPUT" : false)
      }
      min={props.min}
      type={props.type}
      autoComplete="off"
      placeholder={
        props.selectedValue
          ? props.selectedValue
          : props.defaultValue
          ? props.defaultValue
          : ""
      }
      first={props.first}
      allBorders={props.allBorders}
      value={props.type === "date" ? props.selectedValue : null}
      textCenter={props.textCenter}
    ></Input>
  );
}
