import React from "react";
import styled from "styled-components";
import ReactImageFallback from "react-image-fallback";
import { getImage } from "../../_utils/helper";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const Image = styled.div`
  width: 55px;
  height: 55px;
  object-fit: cover;
  > img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Data = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  p {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
    color: #0e2c5e;
    padding: 3px 0px;
  }
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 18px 15px;
    box-sizing: border-box;
    &:last-child {
      text-align: right;
      padding: 25px 15px;
      .qty {
        font-size: 21px;
        font-weight: 500;
      }
    }
  }
`;

export default function TableRow(props) {
  return (
    <Row>
      <Image>
        <ReactImageFallback
          src={
            props.element.location
              ? getImage(props.element, 82, 84)
              : "/addNew.svg"
          }
          fallbackImage="/not-found.svg"
          alt="Product"
        />
      </Image>
      <Data qty={true}>
        <div>
          <p>{props.element.name}</p>
          <p>${parseFloat(props.element.price).toFixed(2)} MXN</p>
          <p>SKU: {props.element.sku}</p>
        </div>
        <div>
          <p className="qty">{parseFloat(props.element.qty).toFixed(2)}</p>
        </div>
      </Data>
    </Row>
  );
}
