import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import { PRSendEmail, PRUpdate } from "./PasswordRecovery";
import { SignIn, CompleteNewPassword } from "./SignIn";
import bgImg from "../assets/background.svg";
import link from "../assets/icons/link.svg";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 30px 40px 0;
  flex-shrink: 0;
  position: fixed;
  background: white;
  z-index: 1;
  > a > p {
    display: flex;
    align-items: center;
    height: 55px;
    border-radius: 28px;
    padding: 0 32px;
    box-sizing: border-box;
  }
`;

const LogoSloganContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const LogoContainer = styled.figure`
  margin-right: 15px;
`;

const MainContainer = styled.div`
  min-height: 400px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-image: url(${bgImg});
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
  overflow-y: auto;
  padding: 110px 0 50px;
  box-sizing: border-box;
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Space = styled.div`
  width: 30px;
  height: 30px;
`;

const Redirectbutton = styled.p`
  background: #5590ff;
  color: white;
  img {
    padding-left: 10px;
    width: 25px;
  }
`;

export default function LogoutBasicSctructure(props) {
  const [cognitoUserObject, setCognitoUserObjectState] = useState(null);
  const [passwordRecoveryEmail, setPasswordRecoveryEmailState] = useState(null);

  return (
    <LoginContainer>
      <HeaderContainer>
        <LogoSloganContainer
          onClick={() => (window.location = "https://www.wallavi.com")}
        >
          <LogoContainer>
            <img src="/horizontal-logo.svg" alt="Logo"></img>
          </LogoContainer>
        </LogoSloganContainer>
        <a href="https://www.app.depot.center">
          <Redirectbutton>
            <p>Inventario</p>
            <img src={link} alt="Link to app"></img>
          </Redirectbutton>
        </a>
      </HeaderContainer>
      <MainContainer>
        <Switch>
          <Route path="/login/complete-new-password">
            <CompleteNewPassword
              cognitoUserObject={cognitoUserObject}
            ></CompleteNewPassword>
          </Route>
          <Route path="/password-recovery/update">
            <PRUpdate email={passwordRecoveryEmail}></PRUpdate>
          </Route>
          <Route path="/password-recovery">
            <PRSendEmail
              saveEmail={setPasswordRecoveryEmailState}
            ></PRSendEmail>
          </Route>
          <Route path="/">
            <SignIn
              setCognitoUserObjectState={setCognitoUserObjectState}
            ></SignIn>
          </Route>
        </Switch>
        <Space></Space>
      </MainContainer>
    </LoginContainer>
  );
}
