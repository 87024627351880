import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import styled from "styled-components";

import NotFound from "./NotFound.js";
import Sales from "./Sales";
import Orders from "./Orders";
import Profile from "./Profile";

import navItems from "./_utils/navItems.js";
// Icons
import expand from "../assets/icons/expand.svg";
import profile from "../assets/icons/profile.svg";
import profileGreen from "../assets/icons/profile-green.svg";
import shrink from "../assets/icons/shrink.svg";
import PointsOfSale from "./PointsOfSale.js";
import { getPOSData } from "./_utils/data.js";

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
`;

const NavContainer = styled.div`
  width: ${(props) => (props.menu ? "215px" : "66px")};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #323946;
  padding-bottom: 20px;
  flex-shrink: 0;
  box-sizing: border-box;
  > div {
    width: 100%;
  }
  & a,
  span {
    display: flex;
    width: 100%;
    cursor: pointer;
  }
`;

const NavSection = styled.li`
  display: flex;
  align-items: center;
  height: 50px;
  background: ${(props) => props.background && props.background};
  &:hover {
    background: rgba(43, 222, 115, 0.6);
  }
  > a,
  span {
    padding-left: 12px;
    width: 100%;
    height: 100%;
  }
`;

const LogoContainer = styled.figure`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  margin-bottom: ${(props) => (props.menu ? "30px" : "55px")};
  width: ${(props) => (props.menu ? null : "40px")};
  height: ${(props) => (props.menu ? "60px" : "45px")};
  > img {
    width: ${(props) => (props.menu ? null : "30px")};
    height: ${(props) => (props.menu ? null : "35px")};
  }
`;

const IconContainer = styled.figure`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  > img {
    width: 35px;
    height: 35px;
    margin-right: 9px;
  }
  > p {
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => (props.selected ? "#2bde73" : "#FFF")};
  }
`;

export default function Dashboard() {
  const [path, setPath] = useState(window.location.pathname);
  const [menu, setMenu] = useState(true);
  const [firstExecution, setFirstExecution] = useState(true);
  const [availablePOSs, setAvailablePOSs] = useState(false);

  useEffect(() => {
    if (firstExecution) {
      getPOSData().then((response) => {
        if (response !== undefined) {
          sessionStorage.setItem("posSelected", JSON.stringify(response[0]));
          setAvailablePOSs(true);
        }
      });
      setFirstExecution(false);
    }
  }, [firstExecution]);

  return (
    <Router>
      <MainContainer>
        <NavContainer menu={menu}>
          <div>
            <LogoContainer menu={menu}>
              <img
                src={menu ? "/vertical-logo.svg" : "/logoIcon.svg"}
                alt="Logo"
              />
            </LogoContainer>
            <ul>
              {navItems.map((e, i) => {
                return menu ? (
                  <NavSection key={i}>
                    <StyledLink to={e.path} onClick={() => setPath(e.path)}>
                      <IconContainer selected={path === e.path}>
                        <img
                          src={path === e.path ? e.iconActive : e.icon}
                          alt={e.name}
                        />
                        <p>{e.name}</p>
                      </IconContainer>
                    </StyledLink>
                  </NavSection>
                ) : (
                  <NavSection key={i}>
                    <StyledLink
                      key={i}
                      to={e.path}
                      onClick={() => setPath(e.path)}
                    >
                      <IconContainer>
                        <img
                          src={path === e.path ? e.iconActive : e.icon}
                          alt={e.name}
                        />
                      </IconContainer>
                    </StyledLink>
                  </NavSection>
                );
              })}
            </ul>
          </div>
          <div>
            <ul>
              {menu ? (
                <>
                  <NavSection onClick={() => setMenu(!menu)}>
                    <StyledLink to="#">
                      <IconContainer>
                        <img src={shrink} alt="Perfil" />
                        <p>Contraer</p>
                      </IconContainer>
                    </StyledLink>
                  </NavSection>
                  <NavSection>
                    <StyledLink
                      to={process.env.REACT_APP_PROFILE_ROUTE}
                      onClick={() =>
                        setPath(process.env.REACT_APP_PROFILE_ROUTE)
                      }
                    >
                      <IconContainer>
                        <img
                          src={
                            path === process.env.REACT_APP_PROFILE_ROUTE
                              ? profileGreen
                              : profile
                          }
                          alt="Perfil"
                        />
                        <p>Perfil</p>
                      </IconContainer>
                    </StyledLink>
                  </NavSection>
                </>
              ) : (
                <>
                  <NavSection onClick={() => setMenu(!menu)}>
                    <StyledLink to="#">
                      <IconContainer>
                        <img src={expand} alt="Expandir" />
                      </IconContainer>
                    </StyledLink>
                  </NavSection>
                  <NavSection>
                    <StyledLink
                      to={process.env.REACT_APP_PROFILE_ROUTE}
                      onClick={() =>
                        setPath(process.env.REACT_APP_PROFILE_ROUTE)
                      }
                    >
                      <IconContainer>
                        <img
                          src={
                            path === process.env.REACT_APP_PROFILE_ROUTE
                              ? profileGreen
                              : profile
                          }
                          alt="Perfil"
                        />
                      </IconContainer>
                    </StyledLink>
                  </NavSection>
                </>
              )}
            </ul>
          </div>
        </NavContainer>
        <Switch>
          <Route
            path={process.env.REACT_APP_SALES_ROUTE}
            exact
            children={<Sales availablePOSs={availablePOSs}></Sales>}
          />
          <Route
            path={process.env.REACT_APP_ORDERS_ROUTE}
            exact
            children={<Orders availablePOSs={availablePOSs}></Orders>}
          />
          <Route path={process.env.REACT_APP_POSS_ROUTE}>
            <PointsOfSale menu={menu} availablePOSs={availablePOSs} />
          </Route>
          <Route
            path={process.env.REACT_APP_PROFILE_ROUTE}
            exact
            component={Profile}
          />
          <Route path="/" component={NotFound} />
        </Switch>
      </MainContainer>
    </Router>
  );
}
