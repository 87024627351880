import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactImageFallback from "react-image-fallback";

import { getImage } from "../_utils/helper";

const MainContainer = styled.div`
  width: ${(props) => (props.design === "v2" ? "350px" : "300px")};
  min-height: 30px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 10px;
  background: #323946;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 70px;
  left: ${(props) => (props.design === "v2" ? "0px" : null)};
  z-index: 5;
  padding: 6px 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 6px 0px;
  align-items: center;
  min-height: 50px;
  border-radius: 10px;
  padding: 0 10px;
  & figure {
    width: 55px;
    height: 55px;
    margin-right: 8px;
  }
  & img {
    width: 100%;
    height: 100%;
  }
  & p {
    color: white;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
  overflow-x: hidden;
`;

const ProductName = styled.p`
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
`;

const ProductUnits = styled.p`
  font-size: 13px;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & figure {
    width: 35px;
    height: 35px;
    margin: 25px 0;
    position: static;
  }
  & img {
    width: 100%;
    height: 100%;
  }
`;

const NotFoundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  & p {
    color: white;
    text-align: center;
    margin: 10px 0 0 0;
  }
`;

const ImageContainer = styled.figure`
  border-radius: 10px;
  overflow: hidden;
  margin: 5px 0px 5px -3px;
  flex-shrink: 0;
`;

export default function SearchBox(props) {
  const [searchStep, setMenuState] = useState("ITEMS");
  const [isFirstLoad, setLoadState] = useState(true);
  const values = props.data;

  useEffect(() => {
    if (isFirstLoad) {
      if (values.length === 0) {
        setMenuState("NOT_FOUND");
      }
      setLoadState(false);
    }
    if (values.length !== 0) {
      setMenuState("ITEMS");
      setLoadState(true);
    }
  }, [isFirstLoad, props.id, values.length]);

  const cells = [];
  for (let i = 0; i < values.length; i++) {
    let image;
    let name;
    let description;
    if (props.type === "PRODUCT") {
      image = getImage(values[i].images[0], 50, 50);
      name = values[i].name;
      description = `SKU: ${values[i].sku ? values[i].sku : "---"} `;
    } else if (props.type === "WAREHOUSE") {
      image = "/map_thumbnail.svg";
      name = values[i].name;
      description = `${values[i].address.substring(0, 25)}...`;
    } else {
      name = values[i].fullname;
      description = values[i].job_title;
    }

    cells.push(
      <ItemContainer
        key={i}
        onMouseDown={() => {
          props.saveValue(values[i], name);
          props.disableProduct();
        }}
      >
        {image && (
          <ImageContainer>
            <ReactImageFallback
              src={image}
              fallbackImage="/not-found.svg"
              alt="Product or warehouse"
            />
          </ImageContainer>
        )}
        <div>
          <ProductName>{name}</ProductName>
          <ProductUnits>{description}</ProductUnits>
        </div>
      </ItemContainer>
    );
  }

  // Loading step
  const loading = (
    <LoadingContainer>
      <figure>
        <img src="../loading.gif" alt="Loading"></img>
      </figure>
    </LoadingContainer>
  );

  // Not found step
  const notFound = (
    <NotFoundContainer>
      <figure>
        <img src="../notFound.svg" alt="Not Found"></img>
      </figure>
      <p>
        {props.type === "PRODUCT"
          ? "No se ha encontrado ningún artículo"
          : "No se ha encontrado ninguna bodega"}
      </p>
    </NotFoundContainer>
  );

  let step;
  if (searchStep === "ITEMS") {
    step = cells;
  } else if (searchStep === "LOADING") {
    step = loading;
  } else if (searchStep === "NOT_FOUND") {
    step = notFound;
  }

  return <MainContainer design={props.design}>{step}</MainContainer>;
}
