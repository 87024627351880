import React, { useState } from "react";
import Toggle from "react-toggle";
import styled from "styled-components";

const MainContainer = styled.div`
  width: 300px;
  min-height: 80px;
  border-radius: 10px;
  background: #323946;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 70px;
  left: 0px;
  z-index: 5;
  padding: 15px 20px;
  padding-bottom: 12px; /*Compensates the weird space on the bottom that the toggle component has*/
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function InputOutput(props) {
  const [checked, setCheckedState] = useState(
    JSON.parse(sessionStorage.getItem("movement_input_output_type")) || false
  );

  return (
    <MainContainer id="input_output">
      <label>
        <Toggle
          defaultChecked={checked}
          icons={false}
          onChange={(event) => {
            sessionStorage.setItem("movement_input_output_type", !checked);
            setCheckedState(!checked);
            props.saveValue(
              { type: event.target.checked ? "Entrada" : "Salida" },
              event.target.checked ? "Entrada" : "Salida"
            );
            document.getElementById("lbl_tgl").innerHTML = event.target.checked
              ? "Entrada"
              : "Salida";
          }}
        />
        <span id="lbl_tgl">{checked ? "Entrada" : "Salida"}</span>
      </label>
    </MainContainer>
  );
}
