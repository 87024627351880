import React, { useEffect, useState } from "react";
import styled from "styled-components";
import POSnotFound from "./POSnotFound";
import { perfectSpaceForPointSales } from "./_utils/helper";

// styled components
import { SearchBox } from "./styles";

// icons
import bankTransfer from "../assets/icons/bankTransfer.svg";
import cash from "../assets/icons/cash.svg";
import creditCard from "../assets/icons/creditCard.svg";
import voucher from "../assets/icons/voucher.svg";
import profileImgDefault from "../assets/img/default-profile-img.svg";
import { getOrderData, getPOSData } from "./_utils/data";
import emptyIcon from "../assets/icons/emptyPOS.svg";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
  overflow-x: hidden;
  > button {
    width: 329px;
    margin: 20px 32px 10px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 115px;
  padding: 0 40px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

const ContainerPoints = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  overflow-y: auto;
`;

const CardPoint = styled.div.attrs(() => ({ className: "cardPoint" }))`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 367px;
  max-width: 550px;
  max-height: 675px;
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  padding: 34px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  > p {
    font-weight: 500;
  }
  > hr {
    width: 100%;
    color: #e3e3e3;
    margin: 7px 0;
  }
  @media (max-width: 890px) {
    width: 90%;
    max-width: 100%;
  }
  @media (min-width: 1250px) {
    width: 30%;
  } ;
`;

const Image = styled.figure`
  margin-bottom: 15px;
  > img {
    border-radius: 64px;
    width: 95px;
    height: 95px;
    object-fit: contain;
  }
`;

const DetailsPoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > p:first-child {
    font-weight: 500;
    margin-bottom: 19px;
  }
  > p:last-child {
    margin-bottom: 20px;
  }
`;

const DetailsGeneral = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  > p:last-child {
    margin-top: 15px;
  }
`;

const DetailsProducts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
`;

const IconDetail = styled.figure`
  display: flex;
  flex-direction: row;
  align-items: center;
  > img {
    margin: 3px 11px 3px 0;
    width: 37px;
    height: 37px;
    border-radius: 160px;
  }
`;

const Selected = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 60px;
  padding: 0 45px;
  box-sizing: border-box;
  top: -25px;
  left: 47%;
  border-radius: 29px;
  background-color: #5590ff;
  color: #fff;
  transform-origin: -15px 0;
`;

const EmptyContent = styled.div`
  width: 230px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
    line-height: 150%;
  }
  figure {
    width: 75px;
    height: 75px;
    margin-bottom: 30px;
  }
`;

export default function PointsOfSale(props) {
  return props.availablePOSs === false ? <POSnotFound /> : <POSFound />;
}

const POSFound = (props) => {
  const [pointSales, setPointSales] = useState([]);
  const [pointSelected, setPointSelected] = useState(
    sessionStorage.getItem("posSelected")
      ? JSON.parse(sessionStorage.getItem("posSelected"))
      : pointSales.length > 0
      ? pointSales[0]
      : {}
  );

  const setProducts = (array) => {
    var cad = "";
    var arraySize = array.length;
    if (arraySize > 0) {
      let i = 0;
      do {
        if ((cad + array[i].name).length < 85) {
          cad += array[i].name;
          if (array[i + 1]) {
            cad += ", ";
          }
        } else break;
        i++;
      } while (cad.length <= 80 && i <= arraySize - 1);
      if (i < arraySize) {
        cad = cad.slice(0, -2);
        cad += " y " + (arraySize - i) + " productos más.";
      } else {
        cad = cad.replace(
          ", " + array[i - 1].name,
          " y " + array[i - 1].name + "."
        );
      }
    } else cad = "No hay productos registrados.";
    return cad;
  };

  const setPaymentIcon = (id) => {
    switch (id) {
      case 1:
        return cash;
      case 2:
        return creditCard;
      case 3:
        return voucher;
      case 4:
        return bankTransfer;
      default:
        break;
    }
  };

  const listener = () => {
    perfectSpaceForPointSales("cardPoint", "pointsContainer");
  };

  useEffect(() => {
    getPOSData().then((response) => {
      setPointSales(response);
      perfectSpaceForPointSales("cardPoint", "pointsContainer");
      window.addEventListener("resize", listener);
      document
        .getElementById("pointsContainer")
        .addEventListener("resize", listener);
    });
  }, []);

  const [flag, setFlag] = useState(0);
  useEffect(() => {
    if (flag === 1) {
      document
        .getElementById("pointsContainer")
        .addEventListener("resize", listener);
    }
    return document
      .getElementById("pointsContainer")
      .removeEventListener("resize", listener);
  }, [flag]);

  useEffect(() => {
    listener();
  }, [pointSales, props.menu]);

  return (
    <Container>
      <Header>
        <SearchBox
          id="search"
          type="text"
          autoComplete="false"
          placeholder="Buscar por sucursal"
          onChange={(event) => {
            if (event.target.value === "") {
              getPOSData().then((response) => setPointSales(response));
            } else {
              getPOSData().then((response) => {
                let points = response;
                setPointSales(
                  points.filter(
                    (e) =>
                      `${e.warehouses.name}`
                        .toLocaleLowerCase()
                        .indexOf(event.target.value.toLocaleLowerCase()) > -1
                  )
                );
              });
            }
          }}
        />
      </Header>
      <ContainerPoints id="pointsContainer">
        {pointSales.length > 0 ? (
          pointSales.map((e, i) => (
            <>
              <CardPoint
                id={`point${e.id}`}
                key={i}
                onClick={() => {
                  sessionStorage.setItem("posSelected", JSON.stringify(e));
                  getOrderData({ force: true });
                  setPointSelected(e);
                }}
              >
                {pointSelected.pos === e.pos && (
                  <Selected>
                    <div>Seleccionado</div>
                  </Selected>
                )}
                <Image>
                  <img
                    alt="Static mapbox map"
                    src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+323946(${e.warehouses.longitude},${e.warehouses.latitude})/${e.warehouses.longitude},${e.warehouses.latitude},15,0.00,0.00/250x250@2x?access_token=pk.eyJ1IjoicmxlZ29ycmV0YSIsImEiOiJjaXYzZDBwY2YwMHprMnpxbm93aW5ubDkxIn0.XvwIpNBJK-ZS3VsiTRNv4w`}
                  />
                </Image>
                <DetailsPoint>
                  <p>{e.warehouses.name}</p>
                  <p>{e.warehouses.address}</p>
                </DetailsPoint>
                <hr />
                <DetailsGeneral>
                  {e.payments.map(
                    (ele, ind) =>
                      ind < 2 && (
                        <IconDetail key={ind}>
                          <img src={setPaymentIcon(ele.id)} alt={ele.name} />
                          <p>{ele.name}</p>
                        </IconDetail>
                      )
                  )}
                  {e.payments.length > 2 && (
                    <p>Y {e.payments.length - 2} más</p>
                  )}
                </DetailsGeneral>
                <hr />
                <DetailsGeneral>
                  {e.sellers.map(
                    (ele, ind) =>
                      ind < 2 && (
                        <IconDetail key={ind}>
                          <img
                            src={ele.picture ? ele.picture : profileImgDefault}
                            alt={ele.fullname}
                          />
                          <p>{ele.fullname}</p>
                        </IconDetail>
                      )
                  )}
                  {e.sellers.length > 2 && <p>Y {e.sellers.length - 2} más</p>}
                </DetailsGeneral>
                <hr />
                <DetailsProducts>
                  <p>{setProducts(e.articles)}</p>
                </DetailsProducts>
              </CardPoint>
            </>
          ))
        ) : (
          <EmptyContent>
            <figure>
              <img src={emptyIcon} alt="Icon" />
            </figure>
            <p>No hay productos disponibles en el punto de venta</p>
          </EmptyContent>
        )}
      </ContainerPoints>
      {flag === 0 && setFlag(1)}
    </Container>
  );
};
