import React, { useState } from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  min-height: 80px;
  border-radius: 10px;
  background: #323946;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 70px;
  right: -20px;
  z-index: 5;
  padding: 15px 20px;
  box-sizing: border-box;
`;

const ItemStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  box-sizing: border-box;
  color: #fff;
`;

const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border: solid 2px #ffffff;
  background-color: ${(props) => (props.selected ? "#2bde73" : "#FFF")};
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
`;

export default function OrderState(props) {
  const [statusList, setStatusList] = useState(
    sessionStorage.getItem("movement_order_state_type")
      ? JSON.parse(sessionStorage.getItem("movement_order_state_type"))
      : [
          { name: "Completadas", selected: true },
          { name: "Canceladas", selected: true },
        ]
  );

  return (
    <MainContainer id="order_state">
      {statusList.map((e, i) => {
        return (
          <ItemStatus
            key={i}
            onClick={() => {
              let temp = statusList.slice();
              temp[i]["selected"] = !temp[i]["selected"];
              setStatusList(temp);
              sessionStorage.setItem(
                "movement_order_state_type",
                JSON.stringify(temp)
              );
              props.saveValue(temp);
            }}
          >
            <Checkbox selected={e.selected} />
            <div>
              <p>{e.name}</p>
            </div>
          </ItemStatus>
        );
      })}
    </MainContainer>
  );
}
